@import url('https://fonts.googleapis.com/css?family=Montserrat:600&display=swap');

* {
  margin: 0;
  padding: 0;
  word-break: break-all;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  text-decoration: none;
}
